export default [
  {
    id: "actionOriented",
    titleClient: 'Action-Oriented',
    subtitleClient: 'Focuses on implementing practical steps to improve thought patterns and behaviors, emphasizing tangible actions and measurable outcomes.',
    titleProvider: "Action oriented",
  },
  {
    id: "relationalReflective",
    titleClient: 'Relational and Reflective',
    subtitleClient: 'Engages in active listening, fostering self-awareness and deep understanding of the underlying causes of emotions and behaviors, while building meaningful connections with others.',
    titleProvider: "Relational and reflective",
  },
  {
    id: "creativeIntegrative",
    titleClient: 'Creative and Integrative',
    subtitleClient: 'Employs holistic techniques, such as breathing exercises and mindfulness practices, integrating various approaches to promote overall well-being.',
    titleProvider: "Creative and integrative",
  },
  {
    id: "noPreference",
    titleClient: 'No preference',
    titleProvider: "No preference",
  },
]
