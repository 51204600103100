<script setup>
import {computed} from "vue"
import {useRoute} from "vue-router"
import AleaViewMenuDesktop from "./AleaViewMenuDesktop.vue"
import AleaViewMenuMobile from "./AleaViewMenuMobile.vue"
import {ArrowLeft} from "lucide-vue-next"
import AleaButton from "./AleaButton.vue"
import {useAppStore} from "../../stores/app.js"
import {storeToRefs} from "pinia"
import AleahealthIconPrimary from "../../assets/img/aleahealth-icon-primary.svg?url"

const props = defineProps({
  menuItems: {
    type: Array,
    required: true,
  },
})

const appStore = useAppStore()
const route = useRoute()

const {app} = storeToRefs(appStore)

const showBackButton = computed(
  () => route.meta.backRouteName && route.meta.backRouteName !== route.name ? true : false
)
const menuItemsEnriched = computed(() => {
  return props.menuItems.map((item) => {
    return {
      ...item,
      active: route.matched.some((r) => r.name === item.route.name),
    }
  })
})
</script>

<template>
  <div class="grid w-full md:pl-[250px]">
    <!-- menu / sidebar -->
    <AleaViewMenuDesktop :menuItemsEnriched />
    <AleaViewMenuMobile :menuItemsEnriched />

    <!-- main -->
    <div class="flex flex-col relative">
      <!-- header -->
      <header
        class="fixed top-0 right-0 left-0 md:left-[250px] z-10 flex items-center justify-center gap-1 border-b bg-white px-4 h-14 shadow-sm">

        <!-- alea icon -->
        <img :src="AleahealthIconPrimary" class="absolute left-4 size-8 md:hidden" v-if="!showBackButton" />

        <!-- back button -->
        <AleaButton
          class="absolute left-2"
          :icon="ArrowLeft"
          size="icon"
          variant="naked"
          :route="{name: $route.meta.backRouteName, params: $route.params}"
          v-if="showBackButton" />

        <!-- title -->
        <h2 class="text-xl font-semibold">{{ $route.meta.pageTitle }}</h2>
        <!-- slot (for buttons) -->
        <div class="absolute right-4">
          <component
            :is="app.header.slot.component"
            v-bind="app.header.slot.props"
            v-if="app.header?.slot?.component" />
        </div>
      </header>

      <!-- main view -->
      <main class="min-h-screen flex flex-col px-4 pb-24 pt-20 w-screen bg-gradient-alea md:w-full h-full">
        <RouterView />
      </main>
    </div>
  </div>
</template>
