export default [
  {
    id: "anxiety",
    titleClient: "Feeling less anxious or stressed",
    titleProvider: "Reducing anxiety",
  },
  {
    id: "depression",
    titleClient: "Overcoming sadness or depression",
    titleProvider: "Managing depression",
  },
  {
    id: "trauma",
    titleClient: "Healing from past trauma",
    titleProvider: "Coping with trauma",
  },
  {
    id: "anger",
    titleClient: "Managing anger",
    titleProvider: "Managing anger",
  },
  {
    id: "relationships",
    titleClient: "Building better relationships",
    titleProvider: "Enhancing relationships",
  },
  {
    id: "confidence",
    titleClient: "Boosting my self-confidence",
    titleProvider: "Improving self-esteem",
  },
  {
    id: "changes",
    titleClient: "Managing life's big changes",
    titleProvider: "Managing life's big changes",
  },
  {
    id: "sleep",
    titleClient: "Improving my sleep",
    titleProvider: "Improving sleep",
  },
  {
    id: "family",
    titleClient: "Dealing with family issues",
    titleProvider: "Dealing with family issues",
  },
  {
    id: "career",
    titleClient: "Finding direction in my career",
    titleProvider: "Finding direction in my career",
  },
  {
    id: "grief",
    titleClient: "Coping with grief or loss",
    titleProvider: "Coping with grief or loss",
  },
  {
    id: "substance",
    titleClient: "Overcoming substance use issues",
    titleProvider: "Addressing substance abuse",
  },
  {
    id: "other",
    titleClient: "Other",
    titleProvider: "Other",
  },
]
